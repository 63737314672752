import cx from 'classnames';
import * as React from 'react';

import { Player } from '../RockPaperScissors';
import './Result.scss';

interface Props {
  isHidden: boolean;
  player1: Player;
  player2: Player;
  winner: Player;
}

const Result = (props: Props) =>
  props.player1.weapon && props.player2.weapon && (
    <div className={cx('results', { hidden: props.isHidden })}>
      {[1, 2].map((n) => {
        const { name, weapon } = props[`player${n}`];
        return (
          <div key={n}>
            <span className="player-name">{name}</span> chose {weapon}
          </div>
        );
      })}
      <div>
        {props.winner ? (
          <>
            <span className="player-name">{props.winner.name}</span> wins this round!
          </>
        ) : (
          'It’s a tie 👔'
        )}
      </div>
    </div>
  );

export default Result;
