import cx from 'classnames';
import sample from 'lodash/sample';
import * as React from 'react';

import Form from './Form';
import Players from './Players';
import Result from './Result/Result';

import './RockPaperScissors.scss';

export interface Player {
  name: string | null;
  weapon: Weapon | null;
}

interface State {
  player1: Player;
  player2: Player;
  winner: Player | null;
  hasPlayed: boolean;
  isCountingDown: boolean;
  isResetting: boolean;
}

export type Weapon = 'rock' | 'paper' | 'scissors';

export const weapons: Weapon[] = ['rock', 'paper', 'scissors'];

export default class RockPaperScissors extends React.Component {
  public state: State = {
    hasPlayed: false,
    isCountingDown: false,
    isResetting: false,
    player1: {
      name: 'Van',
      weapon: null,
    },
    player2: {
      name: null,
      weapon: null,
    },
    winner: null,
  };

  public setName = (name: string): void => {
    this.setState((state: State) => ({ player2: { ...state.player2, name } }));
  }

  public resetGame = (delay: number): void => {
    this.setState({ isResetting: true });
    setTimeout(
      () =>
        this.setState((state: State) => ({
          isCountingDown: true,
          isResetting: false,
          player1: { ...state.player1, weapon: null },
          player2: { ...state.player2, weapon: null },
          winner: null,
        })),
      delay,
    );
  }

  public playRound = (p2Weapon: Weapon): void => {
    const animationDelay = 3000;
    const resetDelay = this.state.hasPlayed ? 1250 : 0;
    this.resetGame(resetDelay);

    const p1Weapon = sample(weapons);
    const winner = this.decideWinner(p1Weapon, p2Weapon);

    setTimeout(
      () => this.setState({ isCountingDown: false, hasPlayed: true }),
      animationDelay + resetDelay,
    );

    setTimeout(
      () =>
        this.setState((state: State) => ({
          player1: { ...state.player1, weapon: p1Weapon },
          player2: { ...state.player2, weapon: p2Weapon },
          winner,
        })),
      animationDelay + resetDelay + 10,
    );
  }

  public decideWinner = (p1Weapon: Weapon, p2Weapon: Weapon): Player => {
    const p1WinningCombos = ['rock scissors', 'paper rock', 'scissors paper'];
    if (p1Weapon === p2Weapon) {
      return null;
    }
    if (p1WinningCombos.includes(`${p1Weapon} ${p2Weapon}`)) {
      return this.state.player1;
    }
    return this.state.player2;
  }

  public render() {
    const isHidden = this.state.isResetting || this.state.isCountingDown;
    return (
      <div className="rps">
        {this.state.player2.name && (
          <h2
            className={cx('rps-title', {
              hidden: isHidden || this.state.hasPlayed,
            })}>
            Hello <span className="player-name">{this.state.player2.name}</span>!
          </h2>
        )}
        <Result
          winner={this.state.winner}
          player1={this.state.player1}
          player2={this.state.player2}
          isHidden={isHidden}
        />
        <Form
          play={this.playRound}
          player2={this.state.player2}
          setName={this.setName}
          hasPlayed={this.state.hasPlayed}
          isHidden={isHidden}
        />
        <Players
          isResetting={this.state.isResetting}
          isCountingDown={this.state.isCountingDown}
          player1={this.state.player1}
          player2={this.state.player2}
        />
      </div>
    );
  }
}
