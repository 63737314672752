import cx from 'classnames';
import * as React from 'react';

import { Player, Weapon, weapons } from '../RockPaperScissors';
import './Form.scss';

declare global {
  interface Window {
    ga: any;
  }
}

interface Props {
  hasPlayed: boolean;
  isHidden: boolean;
  player2: Player;
  play(weapon: Weapon): void;
  setName(name: string): void;
}

interface State {
  checkedOption: Weapon;
  name: string;
}

class Form extends React.Component<Props> {
  public state: State = {
    checkedOption: 'rock',
    name: '',
  };

  public track(action: string, label: string): void {
    if (window.ga) {
      window.ga('send', 'event', 'RPS', action, label);
    }
  }

  public handleSubmitName = (e: React.FormEvent): void => {
    e.preventDefault();
    const { props, state, track } = this;
    props.setName(state.name);
    track('Submit name', state.name);
  }

  public handleChangeName = (e: React.FormEvent): void => {
    const target = e.target as HTMLInputElement;
    this.setState({ name: target.value });
  }

  public handleSubmitGame = (e: React.FormEvent): void => {
    e.preventDefault();
    const { props, state, track } = this;
    props.play(state.checkedOption);
    const playerType = props.hasPlayed ? 'Returning' : 'New';
    track(
      'Play',
      `${playerType} player - ${state.name} - ${state.checkedOption}`,
    );
  }

  public handleChangeWeapon = (e: React.FormEvent) => {
    const target = e.target as HTMLInputElement;
    this.setState({ checkedOption: target.value });
  }

  public render() {
    return this.props.player2.name ? (
      <form
        className={cx('form form--weapon', { hidden: this.props.isHidden })}
        onSubmit={this.handleSubmitGame}
        onChange={this.handleChangeWeapon}>
        {!this.props.hasPlayed && 'Let’s play a quick game of'}
        <div>
          {weapons.map((weapon: Weapon) => (
            <label key={weapon} className="weapon-label">
              <input
                type="radio"
                name="weapon"
                value={weapon}
                defaultChecked={this.state.checkedOption === weapon}
              />
              {weapon}
            </label>
          ))}
        </div>
        <button type="submit">
          {this.props.hasPlayed ? 'Play again' : 'Play!'}
        </button>
      </form>
    ) : (
      <>
        <h2>Hi, I’m Van</h2>
        <form className="form form--name" onSubmit={this.handleSubmitName}>
          <label className="name-label">What’s your name?</label>
          <input
            type="text"
            value={this.state.name}
            onChange={this.handleChangeName}
            id="nameInput"
            autoFocus
          />
        </form>
      </>
    );
  }
}

export default Form;
