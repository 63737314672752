import cx from 'classnames';
import * as React from 'react';

import { Player } from '../RockPaperScissors';
import './Players.scss';

interface Props {
  isResetting: boolean;
  isCountingDown: boolean;
  player1: Player;
  player2: Player;
}

const Players = ({ isResetting, isCountingDown, player1, player2 }: Props) => (
  <div className="players">
    <div
      className={cx('player player--1', {
        close: isResetting,
        countdown: isCountingDown,
        [player1.weapon]: player1.weapon,
      })}
    />
    <div
      className={cx('player player--2', {
        close: isResetting,
        countdown: isCountingDown,
        [player2.weapon]: player2.weapon,
      })}
    />
  </div>
);

export default Players;
